import axios from 'axios';
import authenticationRepository from "@/Repositories/authenticationRepository.js";
import stores from "@/Stores/stores.js";
import {storeToRefs} from "pinia";
import {handleFeedbackFromServer} from "@ryokutech/brainkit_frontend";
import {router} from "@inertiajs/vue3";

const Axios = axios.create({});

Axios.interceptors.request.use(
    config => {
        // You can modify or log the request here
        // For example, you can add headers:
        // config.headers.Authorization = `Bearer ${token}`;
        return config;
    },
    error => {
        // Handle request errors
        return Promise.reject(error);
    }
);

// Response Interceptor for Axios
Axios.interceptors.response.use(
    response => {
        // Handle or log the response here
        return response;
    },
    async error => {

        const {questionStore, answerStore} = stores();
        const {isLoadingQuestion} = storeToRefs(questionStore);
        const {isLoadingAnswer, isLoadingVoice} = storeToRefs(answerStore);
        const response = error.response.data.feedback;

        //This is thrown in the time check middleware
        if (error.response.status === 477 || error.response.status === 404) {
            isLoadingQuestion.value = false;
            isLoadingAnswer.value = false;
            isLoadingVoice.value = false;

            router.get(route('landing.index'));
            return Promise.reject(error);
        }


        // Check if the request has been retried already
        if (error.config._retry) {
            isLoadingQuestion.value = false;
            isLoadingAnswer.value = false;
            isLoadingVoice.value = false;
            handleFeedbackFromServer({type: response.type, message: response.message});
            return Promise.reject(error);
        }

        // CSRF token mismatch
        if (error.response.status === 419) {
            await authenticationRepository.getCsrf();

            // Marking the request as retried
            error.config._retry = true;

            // Retry the request with the new CSRF token
            return Axios(error.config);
        }

        // Open Ai error
        if (error.response.status === 502) {
            // Marking the request as retried
            error.config._retry = true;

            // Retry the request with the new CSRF token
            return Axios(error.config);
        }

        // Access restricted based on time
        if (error.response.status === 403 && error.response.data.feedback.message === 'Access restricted based on time') {
            return Promise.reject(error);
        }

        handleFeedbackFromServer({type: response.type, message: response.message});
        return Promise.reject(error);
    }
);

export {Axios};
